"use client";

import * as React from "react";
import Image from "next/image";
import Link from "next/link";
import { cn } from "@/src/lib/utils"
import { useSearchParams } from "next/navigation";
import { useState, useEffect, useRef } from "react";
import StockTicker from "../components/stockTicker/stockTicker";
import { Icon } from "../core/icon";
import { createPortalSession, useSeller } from "../lib/api/useSeller";
import { Button } from "@/src/components/ui/button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/src/components/ui/accordion";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/src/components/ui/navigation-menu";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/src/components/ui/sheet";

export const navLinks = [
  {
    text: "About",
    link: "/about",
  },
  {
    text: "Blog",
    link: "/blog",
  },
  {
    text: "Pricing",
    link: "/pricing",
  },
];

export const ctaLinks = [
  {
    text: "Discord",
    link: "https://discord.gg/hKbvHAHvvU",
  },
];

const components: { title: string; href: string; description: string }[] = [
  {
    title: "TikTok virality",
    href: "/docs/primitives/alert-dialog",
    description:
      "Use SellRaze's AI tools to create viral TikTok content.",
  },
  {
    title: "Youtube Automation",
    href: "/docs/primitives/alert-dialog",
    description:
      "Automate YouTube content creation to save time and boost productivity.",
  },
  {
    title: "Faceless content",
    href: "/docs/primitives/alert-dialog",
    description:
      "Create engaging faceless videos with AI avatars and voiceovers.",
  },
  {
    title: "Instagram monetization",
    href: "/docs/primitives/alert-dialog",
    description:
      "Boost Instagram earnings with eye-catching Reels and Stories.",
  },
  {
    title: "Subtitles",
    href: "/docs/primitives/alert-dialog",
    description:
      "Add accurate subtitles quickly using AI-powered generation.",
  },
  // ... (other components)
]

const MobileOption = React.forwardRef<
    React.ElementRef<"a">,
    React.ComponentPropsWithoutRef<"a"> & { imageSrc?: string }
>(({ className, title, children, href = "#", imageSrc, ...props }, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <Link
                    ref={ref}
                    href={href as string}
                    className={cn(
                        "block select-none rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground group",
                        className
                    )}
                    {...props}
                >
                    <div className="flex items-center space-x-2">
                        {imageSrc && <Image src={imageSrc} alt={imageSrc} width={18} height={18} />}
                        <span className="text-sm font-medium leading-none">{title}</span>
                        <Image 
                            src="/rightArrow.png" 
                            alt="arrow" 
                            width={10} 
                            height={10} 
                            className="transform -translate-x-1 opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
                        />
                    </div>
                </Link>
            </NavigationMenuLink>
        </li>
    )
})
MobileOption.displayName = "MobileOption";

const Feature = React.forwardRef<
    React.ElementRef<"a">,
    React.ComponentPropsWithoutRef<"a"> & { imageSrc?: string }
>(({ className, title, children, href = "#", imageSrc, ...props }, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <Link
                    ref={ref}
                    href={href as string}
                    className={cn(
                        "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground group",
                        className
                    )}
                    {...props}
                >
                    <div className="flex flex-row justify-between items-center">

                        <div className="space-y-1">
                            <div className="flex flex-row gap-1 text-sm font-medium leading-none items-center">
                                {title}
                                <Image 
                                    src="/rightArrow.png" 
                                    alt="arrow" 
                                    width={10} 
                                    height={10} 
                                    className="transform -translate-x-4 opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
                                />
                            </div>
                            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                                {children}
                            </p>
                        </div>
                        {imageSrc && <Image src={imageSrc} alt={imageSrc} width={52} height={52} />}
                    </div>

                </Link>
            </NavigationMenuLink>
        </li>
    )
})
Feature.displayName = "Feature"

const MobileFeature: React.FC<{ href: string; imageSrc?: string; title: string; children?: React.ReactNode }> = ({ href, imageSrc, title, children }) => {
  return (
    <Link
      href={href}
      className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground group"
    >
      <div className="flex flex-row justify-between items-center">
        <div className="space-y-1">
          <div className="flex flex-row gap-1 text-sm font-medium leading-none items-center">
            {title}
            <Image 
              src="/rightArrow.png" 
              alt="arrow" 
              width={10} 
              height={10} 
              className="transform -translate-x-4 opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
            />
          </div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </div>
        {imageSrc && <Image src={imageSrc} alt={title} width={52} height={52} />}
      </div>
    </Link>
  );
};

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const searchParams = useSearchParams();
  const affiliate = searchParams?.get("affiliate") || "";
  const referral = searchParams?.get("referral") || "";
  const source = searchParams?.get("source") || "";
  const { seller, loading } = useSeller();

  const navRef = useRef<HTMLDivElement>(null);
  const showNavBar = () => {
    navRef.current ? navRef.current.classList.toggle("responsive_nav") : null;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    console.log(isMobile);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  const trackAddToCartFacebookConversion = () => {
    if (affiliate == "Facebook") {
      const pixelId = process.env.NEXT_PUBLIC_META_PIXEL_ID || "";
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.init(pixelId, undefined, options);
          ReactPixel.track("AddToCart");
        });
    }
  };

  const trackPricingPageFacebookConversion = () => {
    if (affiliate == "Facebook") {
      const pixelId = process.env.NEXT_PUBLIC_META_PIXEL_ID || "";
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.init(pixelId, undefined, options);
          ReactPixel.track("ViewContent");
        });
    }
  };

  const paramsArray = [];
  if (affiliate !== "") {
    paramsArray.push(`affiliate=${affiliate}`);
  }

  if (referral !== "") {
    paramsArray.push(`referral=${referral}`);
  }

  if (source !== "") {
    paramsArray.push(`source=${source}`);
  }
  const params = paramsArray.length > 0 ? `?${paramsArray.join("&")}` : "";

  return (
    <>
      <span className="fixed-header-spacer"></span>
      <div className="fixed-header-container">
        {/* <StockTicker></StockTicker> */}
        <header className="[ launch-header ] flex items-center justify-between px-4 py-2">
          <Link href={"/" + params}>
            <picture>
              <source srcSet="/sellRazeLogo.png" media="(min-width: 600px)" height="24" width="120" />
              <Image src="/sellraze_mark.svg" width="36" height="36" alt="Sellraze landing page" />
            </picture>
          </Link>
          {isMobile && (
                <Sheet open={menuOpen} onOpenChange={setMenuOpen}>
                    <SheetTrigger asChild>
                      <button className="p-0 transition-transform duration-200 hover:scale-110">
                          <Image src="/hamburger.png" alt="Menu" width={21} height={21} />
                      </button>
                    </SheetTrigger>
                    <SheetContent side="top" className={`bg-white w-full px-8 py-8 font-sans rounded-b-2xl`}>
                            <Accordion type="single" collapsible className="mt-10">
                                <AccordionItem value="features">
                                    <AccordionTrigger>Features</AccordionTrigger>
                                    <AccordionContent>
                                      <ul className="space-y-3">
                                          <li className="row-span-3">
                                              <p className="text-[10px] pb-1 mb-2 pl-3 border-b">VIDEO CONCEPTS</p>
                                              <div>
                                                  <MobileFeature href="https://crayo.ai/create/story-video?step=1" imageSrc="/menuStoryVideo.png" title="Story video">
                                                      Generate an AI-generated story video
                                                  </MobileFeature>
                                                  <MobileFeature href="https://crayo.ai/tools/voiceovers?step=0" imageSrc="/menuVoiceover.png" title="AI voiceover">
                                                      Make high quality voiceovers in seconds
                                                  </MobileFeature>
                                                  <MobileFeature href="https://crayo.ai/create/text-video?step=0" imageSrc="/menuChatGPT.png" title="Fake texts video">
                                                      Create fake text conversation videos for Instagram, TikTok, and Shorts
                                                  </MobileFeature>
                                              </div>
                                          </li>
                                          <li className="relative">
                                              <div className="absolute inset-0"></div>
                                              <div className="relative z-10">
                                                  <p className="text-[10px] pb-1 mb-2 pl-3 border-b">EDITING TOOLS</p>

                                                  <MobileFeature href="https://crayo.ai/tools/ideas" title="AI-generated story script"/>
                                                  <MobileFeature href="https://crayo.ai/tools/images" title="AI images"/>
                                                  <MobileFeature href="https://crayo.ai/create/blur-video?step=1" title="Blur video"/>
                                                  <MobileFeature href="https://crayo.ai/tools/youtube-downloader" title="Youtube downloader"/>
                                                  <MobileFeature href="https://crayo.ai/tools/tiktok-downloader" title="Tiktok downloader"/>

                                              </div>
                                          </li>
                                      </ul>
                                    </AccordionContent>
                                </AccordionItem>
                                <AccordionItem value="resources">
                                    <AccordionTrigger>Resources</AccordionTrigger>
                                    <AccordionContent>
                                      <ul className="grid gap-3">
                                          <MobileFeature href="https://crayo.tolt.io/login" title="Affiliate program">
                                              Earn 20% on all paid referrals to SellRaze.
                                          </MobileFeature>
                                          <MobileFeature href="/blog" title="Blog">
                                              Find news, insights, and best practices.
                                          </MobileFeature>
                                          <MobileFeature href="https://crayo.ai/discord" title="Community Discord">
                                              Join for customer support and feedback.
                                          </MobileFeature>
                                          <MobileFeature href="https://crayo.ai/guide" title="Guide">
                                              Learn how to maximize the use of SellRaze.
                                          </MobileFeature>
                                      </ul>
                                    </AccordionContent>
                                </AccordionItem>
                                <AccordionItem value="mobile">
                                    <AccordionTrigger>Mobile</AccordionTrigger>
                                    <AccordionContent>
                                      <ul className="flex flex-col w-[510px] p-4">
                                        <MobileOption href="https://crayo.tolt.io/login" imageSrc="/iconAppStore.png" title="Download on iOS App Store" />
                                        <MobileOption href="/blog" imageSrc="/iconGooglePlay.png" title="Download on Google Play" />
                                      </ul>
                                    </AccordionContent>
                                </AccordionItem>
                            </Accordion>
                            <Link href="https://crayo.ai/discord" className="text-[18px] block py-3 border-b tracking-tight text-start align-center items-center justify-center font-sans">
                                Join Discord
                            </Link>
                            <Link href="/pricing" className="text-[18px] block py-3 border-b tracking-tight text-start align-center items-center justify-center font-sans">
                                Pricing
                            </Link>
                            <div className="flex flex-col mt-16 space-y-4">
                                <Button href="https://crayo.ai/dashboard" className="w-full font-sans space-x-3 bg-crayoBlue hover:bg-crayoBlue/80">
                                  <Image src="/lightningWhite.png" alt="Lightning" width={14} height={14} />
                                  <span className="text-white font-sans-bold">Get started</span>
                                </Button>
                            </div>
                    </SheetContent>
                </Sheet>

            // <nav className="[ launch-nav ] absolute right-0 transform" ref={navRef}>
            //   <>
            //     {isMobile && (
            //       <Link href="/" rel="noopener noreferrer" className="[ landing-link login-nav ]">
            //         Home
            //       </Link>
            //     )}
            //     {navLinks.map((link) => (
            //       <Link
            //         className="[ landing-link ] text-[18px] font-semibold text-zinc-400 hover:text-zinc-300 transition-colors duration-300"
            //         href={link.link + params}
            //         key={`${link.link} + ${link.text}`}
            //       >
            //         {link.text}
            //       </Link>
            //     ))}
            //     <div
            //       className={`flex ${isMobile ? "flex-col space-y-4" : "flex-row space-x-4"} align-center justify-center`}
            //     >
            //       <Button variant="secondary" className={`bg-transparent`} href="/login">
            //         <span className={`font-semibold ${isMobile ? "text-[18px]" : ""}`}>Log in</span>
            //       </Button>
            //       <Button variant="default" href="/login-option">
            //         <span className={`font-semibold ${isMobile ? "text-[18px]" : ""}`}>Sign up</span>
            //       </Button>
            //     </div>
            //     {isMobile && (
            //       <div className="flex flex-row space-x-4">
            //         <Link
            //           className="[ ss-large roundedfull ] rounded-full bg-gray-200 p-4"
            //           href={"https://discord.gg/hKbvHAHvvU" + params}
            //           key="https://discord.gg/hKbvHAHvvU"
            //           target="_blank"
            //           rel="noopener noreferrer"
            //         >
            //           <Image src="/discord_badge.svg" alt="" height="18" width="18" />
            //         </Link>
            //         <Link
            //           className="[ ss-large roundedfull ] rounded-full bg-gray-200 p-4"
            //           href={"https://twitter.com/SellRaze" + params}
            //           key="https://twitter.com/SellRaze"
            //           target="_blank"
            //           rel="noopener noreferrer"
            //         >
            //           <Image src="/twitter.svg" alt="" height="18" width="18" />
            //         </Link>
            //         <Link
            //           className="[ ss-large roundedfull ] rounded-full bg-gray-200 p-4"
            //           href={"https://www.instagram.com/sellraze" + params}
            //           key="https://www.instagram.com/sellraze"
            //           target="_blank"
            //           rel="noopener noreferrer"
            //         >
            //           <Image src="/instagram.svg" alt="" height="18" width="18" />
            //         </Link>
            //       </div>
            //     )}
            //   </>
            //   <div onClick={showNavBar} className="[ hamburger close-navbar ] cursor-pointer">
            //     <Icon icon="x-close" width="44" height="44" />
            //   </div>
            // </nav>
          )}
          <nav className="hidden lg:flex items-center">
            <NavigationMenu className="w-1/2 h-full">
              <NavigationMenuList className="space-x-2">
                  <NavigationMenuItem>
                      <NavigationMenuTrigger className="text-gray-600 hover:text-gray-900 transition-colors duration-300">Product</NavigationMenuTrigger>
                      <NavigationMenuContent>
                          <ul className="grid md:w-[550px] lg:w-[650px] lg:grid-cols-[1.25fr_.75fr] relative">
                              <li className="row-span-3 p-4 border-r border-gray-200">
                                  <p className="text-[10px] pb-1 font-sfmono">FEATURES</p>
                                  <div>
                                      {/* <Feature href="https://crayo.ai/create/story-video?step=1" imageSrc="/menuStoryVideo.png" title="Story video">
                                          Generate an AI-generated story video
                                      </Feature>
                                      <Feature href="https://crayo.ai/tools/voiceovers?step=0" imageSrc="/menuVoiceover.png" title="AI voiceover">
                                          Make high quality voiceovers in seconds
                                      </Feature>
                                      <Feature href="https://crayo.ai/create/text-video?step=0" imageSrc="/menuChatGPT.png" title="Fake texts video">
                                          Create fake text conversation videos for Instagram, TikTok, and Shorts
                                      </Feature> */}
                                  </div>
                              </li>
                              <li className="relative">
                                  <div className="absolute inset-0"></div>
                                  <div className="p-4 relative z-10">
                                      <p className="text-[10px] pb-1 font-sfmono">WHAT'S NEW</p>
{/* 
                                      <Feature href="https://crayo.ai/tools/ideas" title="AI-generated story script"/>
                                      <Feature href="https://crayo.ai/tools/images" title="AI images"/>
                                      <Feature href="https://crayo.ai/create/blur-video?step=1" title="Blur video"/>
                                      <Feature href="https://crayo.ai/tools/youtube-downloader" title="Youtube downloader"/>
                                      <Feature href="https://crayo.ai/tools/tiktok-downloader" title="Tiktok downloader"/> */}

                                  </div>
                              </li>
                          </ul>
                      </NavigationMenuContent>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                      <NavigationMenuTrigger className="text-gray-600 hover:text-gray-900 transition-colors duration-300">Resources</NavigationMenuTrigger>
                      <NavigationMenuContent>
                          <ul className="grid w-[400px] p-4 md:w-[500px] lg:grid-cols-2 lg:w-[600px]">
                              <Feature href="https://sellraze.tolt.io/login" title="Affiliate program">
                                  Earn 20% on all paid referrals to SellRaze.
                              </Feature>
                              <Feature href="/blog" title="Blog">
                                  Find news, insights, and best practices.
                              </Feature>
                              <Feature href="https://discord.gg/hKbvHAHvvU" title="Community Discord">
                                  Join for customer support and feedback.
                              </Feature>
                              <Feature href="https://sellraze.com/about" title="About">
                                  Read more about our mission.
                              </Feature>
                          </ul>
                      </NavigationMenuContent>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                      <NavigationMenuTrigger className="text-gray-600 hover:text-gray-900 transition-colors duration-300">Mobile</NavigationMenuTrigger>
                      <NavigationMenuContent className="">
                          <ul className="flex flex-row w-[510px] p-4">
                              <MobileOption href="https://crayo.tolt.io/login" imageSrc="/iconAppStore.png" title="Download on iOS App Store" />
                              <MobileOption href="/blog" imageSrc="/iconGooglePlay.png" title="Download on Google Play" />
                          </ul>
                      </NavigationMenuContent>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                      <Link href="/pricing" legacyBehavior passHref>
                          <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                              Pricing
                          </NavigationMenuLink>
                      </Link>
                  </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          </nav>
          <div className="hidden lg:flex items-center space-x-4">
            <Button variant="ghost" className="text-gray-600 hover:text-gray-900" asChild>
              <Link href="/login">Log in</Link>
            </Button>
            <Button variant="default" className="bg-blue-600 hover:bg-blue-700 text-white" asChild>
              <Link href="/login-option">Sign up</Link>
            </Button>
          </div>
          {/* <div className="[ flow-row-large full-screen] block lg:hidden">
            <div onClick={showNavBar} className="[ hamburger ] cursor-pointer p-2">
              <Image src="/hamburger.png" alt="Menu" width={28} height={28} />
            </div>
          </div> */}
        </header>
      </div>
    </>
  );
};
export default Header;
